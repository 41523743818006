/* lib overrides */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuDyfMZg.ttf) format('truetype');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuOKfMZg.ttf) format('truetype');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfMZg.ttf) format('truetype');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuI6fMZg.ttf) format('truetype');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuGKYMZg.ttf) format('truetype');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuFuYMZg.ttf) format('truetype');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuDyYMZg.ttf) format('truetype');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuBWYMZg.ttf) format('truetype');
}
.ln-nav {
  width: 100%;
  gap: 0;
  max-height: 100%;
}
.ln-sidebar {
  padding: 0;
  background: #fff;
  overflow: scroll;
  border: 1px solid #333;
}
.ln-content {
  border: 1px solid #333;
  border-left: none;
  min-height: 100vh;
}
.ln-image {
  cursor: pointer;
  height: auto;
  overflow: hidden;
}
.ln-page > div {
  padding: 0;
  padding-bottom: 80px;
}
.ln-page > .ln-column {
  gap: 0;
}
.ln-page .ln-backdrop {
  position: fixed !important;
}
.ln-lightbox .ln-image {
  cursor: default;
  pointer-events: none;
}
.ln-lightbox .ln-image .ln-img {
  max-height: 90vh;
  max-width: 96vw;
  width: 96vw;
  object-fit: scale-down;
}
.ln-lightbox .portfolio-grid-image {
  max-width: 100%;
  overflow: hidden;
}
.ln-lightbox .portfolio-grid-image.ln-image {
  max-width: 100%;
  width: 100%;
}
.ln-lightbox .portfolio-grid-image.ln-image .ln-img {
  max-width: 100%;
}
.work-section {
  border-top: 1px solid #333 !important;
  padding: 40px !important;
  width: 100% !important;
}
.work-section .ln-img {
  object-fit: contain;
  min-width: 100%;
  width: 100%;
  height: auto;
  object-position: center center;
}
.work-section-header {
  width: 100% !important;
}
.work-section-header .ln-image {
  overflow: hidden !important;
}
.work-section-header .ln-grid {
  grid-template-areas: 'a b';
}
.work-section-header .a {
  grid-area: a;
  border-right: 1px solid #333;
}
.work-section-header .b {
  grid-area: b;
}
.work-section-header .work-section-header-content-container {
  padding: 40px !important;
}
.work-section-header .work-section-header-image-container {
  background: #333;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
.work-section-header .work-section-header-image-container .ln-image {
  width: 100%;
  height: 100%;
}
.work-section-header .work-section-header-image-container .ln-image .ln-img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.work-section-grid {
  max-width: 100% !important;
  width: 100% !important;
}
.work-section-grid .portfolio-grid-item {
  max-width: 100%;
  overflow: hidden;
  aspect-ratio: 8 / 5;
}
.work-section-grid .portfolio-grid-item .work-section-image-container {
  width: 100%;
  height: 100%;
}
.work-section-grid .portfolio-grid-item .work-section-image-container .work-section-image {
  border: 1px solid #333;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.work-section-grid .portfolio-grid-item .work-section-image-container .ln-positioned > div > div > div {
  border-top-color: #c6abd7 !important;
  border-left-color: #c6abd7 !important;
}
.work-section-grid .portfolio-grid-item.--portrait {
  aspect-ratio: 8.5 / 11;
}
.work-section-full {
  width: 100% !important;
}
.work-section-full .portfolio-large-item {
  max-width: 100%;
  height: auto;
  width: 100%;
  align-items: center;
}
.work-section-full .portfolio-large-item .portfolio-full-image {
  max-width: 100%;
  height: auto;
  object-fit: contain;
}
.ln-heading {
  font-family: 'Inter' !important;
}
.ln-heading-1 {
  font-size: 24px;
  font-weight: 800;
  line-height: 1.5;
}
.ln-heading-2 {
  font-size: 20px;
  font-weight: 800;
  line-height: 1.5;
}
.ln-paragraph {
  font-family: 'Inter' !important;
}
.ln-paragraph.--default {
  font-size: 14px;
  line-height: 24px;
}
.ln-paragraph.--large.--bold {
  font-size: 16px;
  font-weight: 800;
  line-height: 1.5;
}
.ln-text {
  font-family: 'Inter' !important;
}
#faux-auth {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#faux-auth .faux-auth-content {
  height: 100vh;
  padding: 80px;
  width: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
}
.bb {
  border-bottom: 1px solid #333;
}
#home .work-section-header .ln-image {
  max-height: 900px !important;
}
.ln-anchor {
  display: inline-flex !important;
  font-weight: 600 !important;
}
.ln-list .ln-li {
  list-style: none !important;
}
